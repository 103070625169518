<template>
  <div id="main-nav">
    <div class="base-container">
      <div class="primary-nav-contain">
        <a href="/" aria-current="page" class="logo"
          ><img
            src="../../assets/images/img/artemisads-logo.svg"
            loading="lazy"
            alt="Brand logo"
        /></a>
        <div class="sign-btn" v-if="!store.userInfo">
          <a-button type="primary" size="middle">
            <a @click="goLogin">Sign In</a>
          </a-button>
          <a-button type="default" size="middle" v-show="currenHost">
            <a href="/sign-up">Sign Up</a>
          </a-button>
        </div>
        <div class="user-info" v-if="store.userInfo" ref="userInfoRef">
          <a-dropdown :getPopupContainer="() => userInfoRef">
            <svg
              class="icon"
              aria-hidden="true"
              style="fill: rgba(15, 6, 41, 0.5)"
            >
              <use xlink:href="#icon-a-wode1"></use>
            </svg>
            <template #overlay>
              <div class="content">
                <div class="title-wrap">
                  <a class="title" :href="linkUrl">
                    <h3>
                      {{
                        store.userInfo?.userName || store.userInfo?.name || ''
                      }}
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-jiantou-you-cu"></use>
                      </svg>
                    </h3>
                    <p>{{ store.userInfo?.email || '' }}</p>
                  </a>
                </div>
                <a-menu>
                  <a-menu-item key="1">
                    <div class="select-item">
                      <div class="sign-out" @click="onSignOut">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-tuichu"></use>
                        </svg>
                        <span>Sign Out</span>
                      </div>
                    </div>
                  </a-menu-item>
                </a-menu>
              </div>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserInfoStore } from '../../storeData/index'
import { signApi, publisherAdminApi } from '../../server/index'

const router = useRouter()
const props = defineProps(['pageType'])
const userInfoRef = ref()
const store = useUserInfoStore()
const currenHost = computed(() => {
  if (location.host.includes('adm')) {
    return false
  }
  return true
})

const userInfo = store.userInfo
const linkUrl = computed(() => {
  let link = '/seller/dashboard'
  if (userInfo) {
    if (userInfo.status === 'PENDING_CHOOSE_TYPE') {
      link = '/profile'
    }
    if (userInfo.userType === 'publisher') {
      link = '/publisher/dashboard'
      if (userInfo.reviewStatus === 'Rejected') {
        link = '/profile'
      }
    }
    if (userInfo.userType === 'admin') {
      link = '/operator/users'
    }
  }
  return link
})

const goLogin = () => {
  if (location.host.includes('adm')) {
    router.push({ name: 'op_login' })
  } else {
    router.push({ name: 'sign-in' })
  }
}
const onSignOut = async () => {
  let res
  if (userInfo.userType === 'admin') {
    res = await publisherAdminApi.signOut()
  } else {
    res = await signApi.signOut()
  }
  const { success, error, code } = res
  if (success || code === '3008' || code === '3009' || code === '3010') {
    store.setUserInfo('')
    if (userInfo.userType === 'admin') {
      location.href = '/operator/login'
    } else {
      location.href = '/sign-in'
    }
  } else {
    console.log(error)
  }
}
</script>
<style lang="less" scoped>
#main-nav {
  .base-container {
    width: 100%;
    min-width: 1440px;
  }
  .primary-nav-contain {
    display: flex;
    padding: 16px 72px;
  }
  .logo {
    width: 240px;
    height: 50px;
  }
  .user-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: right;
    > svg {
      width: 29px;
      height: 29px;
      align-self: center;
      cursor: pointer;
    }
  }
  .content {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background-color: #fff;
  }
  :deep(.ant-dropdown-menu) {
    box-shadow: none;
    padding: 8px;
    .ant-dropdown-menu-item {
      padding: 8px;
    }
  }
  .title-wrap {
    padding: 8px;
    border-bottom: 1px solid #e8e5ed;
  }
  .title {
    display: block;
    width: 256px;
    padding: 8px 14px 4px 14px;

    border-radius: 8px;
    &:hover {
      background-color: var(--bg-gray-6);
    }
    > h3 {
      font-family: Sora-Bold;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.4px;
      line-height: 19px;
      color: var(--dark-2);
      svg {
        width: 13px;
        height: 13px;
        margin-left: 4px;
        fill: var(--dark-2);
        vertical-align: -1px;
      }
    }
    > p {
      margin-top: 2px;
      opacity: 0.5;
      font-family: Sora, sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: #0e0528;
      letter-spacing: 0.4px;
      line-height: 18px;
    }
  }
  .sign-out {
    cursor: pointer;
    display: flex;
    > svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;
      margin-left: 8px;
      align-self: center;
      fill: var(--dark-1);
    }
    > span {
      color: var(--dark-1);
      font-family: Sora, sans-serif;
      letter-spacing: 0.4px;
      line-height: 19px;
    }
  }
  .sign-btn:deep {
    align-self: center;
    flex: 1;
    display: flex;
    justify-content: right;
    > button {
      border-radius: 50px;
      width: 100px;
      margin-left: 12px;
      display: flex;
      > a {
        font-size: 14px;
        letter-spacing: 0.4px;
        width: 100%;
        height: 100%;
        font-weight: 500;
      }
      &:first-of-type {
        background-color: #835cf2;
        &:hover {
          background-color: #7552d9;
        }
        &:active {
          background-color: #6849c1;
        }
      }
      &:last-of-type {
        border: 2px solid #835cf2;
        background-color: transparent;
        color: #835cf2;
        &:hover {
          border: 2px solid #835cf2;
          background-color: transparent;
          color: #835cf2;
        }
        &:active {
          border: 2px solid #835cf2;
          background-color: transparent;
          color: #835cf2;
        }
      }
    }
  }
  .nav-menu {
    margin-left: 60px;
    align-self: center;
    > a {
      color: #2c2933;
      padding: 8px 0;
      font-weight: 500;
      line-height: 1.5;
      text-decoration: none;
      cursor: pointer;
      margin-left: 32px;
      font-size: 16px;
      font-family: Sora, sans-serif;
    }
  }
}
</style>